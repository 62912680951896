/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/MaterialIcons/MaterialIcons-Regular.eot");
    src: url("../fonts/MaterialIcons/MaterialIcons-Regular.eot?iefix") format("embedded-opentype"),
        url("../fonts/MaterialIcons/MaterialIcons-Regular.woff2") format("woff2"),
        url("../fonts/MaterialIcons/MaterialIcons-Regular.woff") format("woff")
        url("../fonts/MaterialIcons/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}
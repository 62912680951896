/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/Lato/lato-v23-latin-100.eot");
    src: url("../fonts/Lato/lato-v23-latin-100.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-100.woff2") format("woff2"),
        url("../fonts/Lato/lato-v23-latin-100.woff") format("woff")
        url("../fonts/Lato/lato-v23-latin-100.ttf") format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/Lato/lato-v23-latin-100.eot");
    src: url("../fonts/Lato/lato-v23-latin-100.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-100.woff2") format("woff2"),
        url("../fonts/Lato/lato-v23-latin-100.woff") format("woff")
        url("../fonts/Lato/lato-v23-latin-100.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Lato/lato-v23-latin-300.eot");
    src: url("../fonts/Lato/lato-v23-latin-300.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-300.ttf") format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Lato/lato-v23-latin-300.eot");
    src: url("../fonts/Lato/lato-v23-latin-300.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-300.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato/lato-v23-latin-regular.eot");
    src: url("../fonts/Lato/lato-v23-latin-regular.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-regular.woff2") format("woff2"),
        url("../fonts/Lato/lato-v23-latin-regular.woff") format("woff")
        url("../fonts/Lato/lato-v23-latin-regular.ttf") format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato/lato-v23-latin-regular.eot");
    src: url("../fonts/Lato/lato-v23-latin-regular.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-regular.woff2") format("woff2"),
        url("../fonts/Lato/lato-v23-latin-regular.woff") format("woff")
        url("../fonts/Lato/lato-v23-latin-regular.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato/lato-v23-latin-700.eot");
    src: url("../fonts/Lato/lato-v23-latin-700.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-700.ttf") format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato/lato-v23-latin-700.eot");
    src: url("../fonts/Lato/lato-v23-latin-700.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-700.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Lato/lato-v23-latin-900.eot");
    src: url("../fonts/Lato/lato-v23-latin-900.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-900.ttf") format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Lato/lato-v23-latin-900.eot");
    src: url("../fonts/Lato/lato-v23-latin-900.eot?iefix") format("embedded-opentype"),
        url("../fonts/Lato/lato-v23-latin-900.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }